import React, {forwardRef} from 'react';

export const Photo = forwardRef(({url, index, faded, style, width, height, handleRemoveClicked, listeners, ...props}, ref) => {
  const inlineStyles = {
    opacity: faded ? '0.2' : '1',
    transformOrigin: '0 0',
    height: height,
    width: width,
    gridRowStart: null,
    gridColumnStart: null,
    backgroundImage: `url("${url}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: 'grey',
    cursor: 'grab',
    ...style,
  };

  const dragHandleStyles = {
    width: "100%",
    height: "100%"
  }

  //return <div className='photo' ref={ref} style={inlineStyles} {...props}><span className='remove' onClick={() => { console.log('click'); }}>X</span></div>;
  return <div className='photo' ref={ref} style={inlineStyles} {...props}><div {...listeners} style={dragHandleStyles}></div><span className='remove' onClick={handleRemoveClicked}>X</span></div>;
});
