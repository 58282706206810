import React, { useContext } from "react";
import Dropzone from "react-dropzone-uploader";
import AppStateContext from "../Contexts/AppStateContext";

export const MultiFileUpload = () => {
    const context = useContext(AppStateContext);

    return (
        <>
            <Dropzone
                getUploadParams={context.getUploadParams}
                onChangeStatus={context.handleUploadChangeStatus}
                accept="image/*"
                />
        </>
    )
};