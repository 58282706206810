import './App.css';
import { AppStateProvider } from './Contexts/AppStateContext';
import { AppBar, Box, Toolbar } from '@material-ui/core';
import LoginDialog from './Components/LoginDialog';
import PortfolioManager from './Components/PortfolioManager';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import 'react-dropzone-uploader/dist/styles.css'

function App() {
  return (
    <AppStateProvider>
      <Box className="mainContent">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<PortfolioManager />} />
            <Route path="/:id" element={<PortfolioManager />} />
          </Routes>
        </BrowserRouter>
      </Box>
      <LoginDialog />
      </AppStateProvider>
  );
}

export default App;
