import React from 'react';
import { MultiFileUpload } from './MultiFileUpload';

export function PhotoGrid({children, itemWidth, itemHeight}) {
  return (<>
    <MultiFileUpload />
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fill, ${itemWidth})`,
        gridTemplateRows: `repeat(auto-fill, ${itemHeight})`,
        gridGap: 10,
        padding: 10,
      }}
    >
      {children}
    </div>
    </>
  );
}
