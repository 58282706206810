import React, { useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import AppStateContext from '../Contexts/AppStateContext';

import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    MouseSensor,
  } from '@dnd-kit/core';

  import {
    SortableContext,
    rectSortingStrategy
  } from '@dnd-kit/sortable';
import { SortablePhoto } from './SortablePhoto';
import { PhotoGrid } from './PhotoGrid';
import { useContext } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom/dist/umd/react-router-dom.development';

const size = {
    width: 240,
    height: 160
}

const PortfolioManager = (props) => {
    const context = useContext(AppStateContext);

    const [activeId, setActiveId] = useState(null);
    const [multiplier, setMultiplier] = useState(1);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth - 37);
    const [columns, setColumns] = useState(4);
    const [width, setWidth] = useState(size.width);
    const [height, setHeight] = useState(size.height);
    const [finalMultiplier, setFinalMultiplier] = useState(1);

    let { id } = useParams();

    function debounce(fn, ms) {
      let timer
      return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
          timer = null
          fn.apply(this, arguments)
        }, ms)
      };
    }

    useEffect(() => {
      if(id) {
        context.selectPortfolio(id);
      } else {
        context.clearPortfolioSelection();
      }

      const debouncedHandleResize = debounce(function handleResize() {
        setScreenWidth(window.innerWidth - 37);
      }, 100);

      window.addEventListener('resize', debouncedHandleResize)
  
      return _ => { window.removeEventListener('resize', debouncedHandleResize) }
    }, []);

    useEffect(() => {
      const baseWidth = (size.width * multiplier);
        
      // eslint-disable-next-line no-restricted-globals
      const availableWidth = screenWidth;

      let columns = availableWidth / baseWidth;

      columns = Math.floor(columns);

      const finalWidth = availableWidth / columns;

      setColumns(columns);
      setFinalMultiplier(finalWidth / baseWidth);
    }, [multiplier, screenWidth]);

    useEffect(() => {
      const columnPaddingFactor = ((columns - 1) * 10) / columns;

      setWidth((size.width * finalMultiplier) - columnPaddingFactor);
      setHeight(size.height * finalMultiplier);
    }, [finalMultiplier, columns]);

    const mouseSensor = useSensor(MouseSensor);

    const keyboardSensor = useSensor(KeyboardSensor);
    const sensors = useSensors( mouseSensor, keyboardSensor);

    function onRemovePictureClicked(picture) {
      context.deletePortfolioPicture(picture);
    }

    function handleDragStart(event) {
        setActiveId(event.active.id);
      }
    
      function handleDragEnd(event) {
        const {active, over} = event;

        if (active.id !== over.id) {
          var pictureIds = context.portfolio.pictures.map((picture) => picture.id);
          context.reorderPortfolioPicture(pictureIds.indexOf(active.id), pictureIds.indexOf(over.id));
        }
    
        setActiveId(null);
      }
    
      function handleDragCancel() {
        setActiveId(null);
      }

    return (
    <Box>
    { context.portfolio?.pictures && (
        <>
            <div className="pinnedButtons">
              <Button className="randomButton" onClick={context.randomizePortfolioPictures}>Randomize</Button>
              <Button disabled={!context.hasChanges()} className="saveButton" onClick={context.saveChanges}>Save Changes</Button>
              <Button disabled={!context.hasChanges()} className="resetButton" onClick={context.resetChanges}>Reset</Button>
            </div>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragCancel={handleDragCancel}
            >
            <SortableContext items={context.portfolio.pictures.map(picture => picture.id)} strategy={rectSortingStrategy}>
                <PhotoGrid itemWidth={`${width}px`} itemHeight={`${height}px`}>
                {context.portfolio.pictures.map((picture, index) => (
                    <SortablePhoto handleRemoveClicked={() => onRemovePictureClicked(picture)} key={picture.id} width={`${width}px`} height={`${height}px`} id={picture.id} url={picture._links.thumbnail.href}  />
                ))}
                </PhotoGrid>
            </SortableContext>
            </DndContext>
        </>
    )}
    { !context.portfolio && context.portfolios && (
        <ul>
            {context.portfolios.map((portfolio) => ( <li key={portfolio.id}><Button href={`/${portfolio.id}`}>{portfolio.name}</Button></li>))}
        </ul>
    ) }
    </Box>
    );
}

export default PortfolioManager;