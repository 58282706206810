import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

import {Photo} from './Photo';

export const SortablePhoto = (props) => {
  const sortable = useSortable({id: props.id});
  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
  } = sortable;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1000 : 1
  };

  return (
    <Photo
      ref={setNodeRef}
      style={style}
      handleRemoveClicked={props.handleRemoveClicked}
      {...props}
      {...attributes}
      listeners={listeners}
    />
  );
};
