import React from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions
} from "@material-ui/core";
import AppStateContext from "../Contexts/AppStateContext";

class LoginDialog extends React.Component {
    static contextType = AppStateContext;

    constructor(props) {
        super(props);
        this.state = { username: "", password:"", authflag:1 };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleChange = (event) => {
      this.setState({ username: event.state.username, password: event.state.password });
    }
    handleSubmit = (event) => {
        event.preventDefault();
        this.context.login({ username: this.state.username, password: this.state.password });
    }
    onEnter = (event) => {
        if(event.key === 'Enter') {
            event.preventDefault();
            this.handleSubmit(event);
        }
    }
    render() {
        return (
        <Dialog open={!this.context.getAccessToken()}>
            <DialogContent>
            <TextField type="email" placeholder="Email"
                                            fullWidth
                                            name="username"
                                            variant="outlined"
                                            value={this.state.username}
                                            onChange={(event) =>
                                            this.setState({
                                            [event.target.name]: event.target.value,
                                            })
                                            }
                                            required
                                            autoFocus
                                            />
                        <TextField
                                            type="password"
                                            placeholder="Password"
                                            fullWidth
                                            name="password"
                                            variant="outlined"
                                            value={this.state.password}
                                            onChange={(event) =>
                                            this.setState({
                                            [event.target.name]: event.target.value,
                                            })
                                            }
                                            required
                                            onKeyPress={this.onEnter}
                                            />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleSubmit}>Login</Button>
            </DialogActions>
        </Dialog>
        );
    }
}
export default LoginDialog;