import React, { Component } from 'react'
import { arrayMoveImmutable } from 'array-move';

const rootUrl = process.env.NODE_ENV === 'production' ? 'https://api.lookatmycode.com/' : 'https://localhost:44366/';

const defaultState = {
    selectPortfolio: { },
    portfolios: [],
    savedPortfolio: null,
    portfolio: null,
    getAccessToken: { },
    reorderPortfolioPicture: { },
    deletePortfolioPicture: { },
    randomizePortfolioPictures: { },
    clearPortfolioSelection: { },
    uploadPictures: { },
    login: { },
    getUploadParams: { },
    handleUploadChangeStatus: { },
    saveChanges: { },
    hasChanges: { },
    resetChanges: { }
};

const AppStateContext = React.createContext(defaultState);

let pausedFileUploads = [];

class AppStateProvider extends Component {
  constructor(props) {
    super(props);

    this.saveChanges = () => {
      this.savePortfolioOrder();
    }

    this.hasChanges = () => {

      if(this.state.portfolio === this.state.savedPortfolio) {
        return false;
      }

      if(this.state.portfolio?.pictures?.length !== this.state.savedPortfolio?.pictures?.length) {
        return true;
      }

      if(this.state.portfolio?.pictures?.length) {
        for(let i = 0; i < this.state.portfolio.pictures.length; i++) {
          if(this.state.portfolio.pictures[i].id !== this.state.savedPortfolio.pictures[i].id) {
            return true;
          }
        }
      }

      return false;
    }

    this.resetChanges = () => {
      this.setState({
        ...this.state,
        portfolio: this.state.savedPortfolio
      });
    }

    this.handleUncaughtError = (response) => {
      console.log(response);
    }

    this.handleResponse = (response, expectResponse = true) => {
      if(response.status === 204) {
        return;
      }
      else if(response.status < 400) {
        return expectResponse ? response.json() : undefined;
      } else if(response.status === 401) {
        this.logout();
      } else {
        throw response;
      }
    }

    this.logout = () => {
      this.setAccessToken(null);
    }

    this.getAccessToken = () => {
      return localStorage.getItem('accessToken') === "null" ? null : localStorage.getItem('accessToken');
    }

    this.setAccessToken = (accessToken) => {
      this.setState({
        accessToken
      });
      localStorage.setItem("accessToken", accessToken);

      if(accessToken) {
          pausedFileUploads.forEach(file => {
            //file.xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
            file.restart();
          });
  
          pausedFileUploads = [];
      }
    }

    this.selectPortfolio = (portfolioId) => {
      this.loadPortfolio(portfolioId);
    }

    this.state = {
      getAccessToken: this.getAccessToken,
      setAccessToken: this.setAccessToken,
      selectPortfolio: this.selectPortfolio,
      reorderPortfolioPicture: this.reorderPortfolioPicture,
      deletePortfolioPicture: this.deletePortfolioPicture,
      randomizePortfolioPictures: this.randomizePortfolioPictures,
      clearPortfolioSelection: this.clearPortfolioSelection,
      uploadPictures: this.uploadPictures,
      login: this.login,
      getUploadParams: this.getUploadParams,
      handleUploadChangeStatus: this.handleUploadChangeStatus,
      saveChanges: this.saveChanges,
      hasChanges: this.hasChanges,
      resetChanges: this.resetChanges
    }
  }

  loadPortfolios = async () => {
    fetch(rootUrl + 'portfolios',
      {
        headers: {
          'Authorization': 'Bearer ' + this.getAccessToken()
        }
      })
      .then(response => this.handleResponse(response))
      .then(data => {
        this.setState({...this.state, portfolios: data });
      })
      .catch(this.handleUncaughtError);
  }

  checkLogin = async () => {
    return fetch(rootUrl + 'token',
      {
        headers: {
          'Authorization': 'Bearer ' + this.getAccessToken()
        }
      })
      .then(response => this.handleResponse(response, false))
      .catch(this.handleUncaughtError);
  }

  loadPortfolio = async (portfolioId) => {
    if(!portfolioId) {
      this.setState({...this.state, portfolio: null, savedPortfolio: null});
      return;
    }

    this.setState({...this.state, portfolio: { id: portfolioId, pictures: [], name: 'Loading...' }, savedPortfolio: { id: portfolioId, pictures: [], name: 'Loading...' }});

    fetch(`${rootUrl}portfolios/${portfolioId}/pictures`,
      {
        headers: {
          'Authorization': 'Bearer ' + this.getAccessToken()
        }
      })
      .then(response => this.handleResponse(response))
      .then(data => {
        const portfolio = this.state.portfolios.find(x => x.id === parseInt(portfolioId));
        portfolio.pictures = data;

        this.setState({...this.state, portfolio: portfolio, savedPortfolio: portfolio});
      })
      .catch(this.handleUncaughtError);
  }

  shuffle = (array) => {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

  randomizePortfolioPictures = () => {
    this.setState(({portfolio}) => ({
      ...this.state,
      portfolio: {
        ...portfolio,
        pictures: this.shuffle([...portfolio.pictures])
      }
    }));
  }

  clearPortfolioSelection = () => {
    this.setState(() => ({
      portfolio: null,
      savedPortfolio: null
    }));
  }

  reorderPortfolioPicture = (oldIndex, newIndex) => {
    this.setState(({portfolio}) => ({
      ...this.state,
      portfolio: {
        ...portfolio,
        pictures: arrayMoveImmutable(portfolio.pictures, oldIndex, newIndex)
      }
    }));
  }

  deletePortfolioPicture = (picture) => {
    this.setState(({portfolio}) => {
      const newPortfolioPictures = [...portfolio.pictures];
      var index = newPortfolioPictures.indexOf(picture);
      if (index !== -1) {
        newPortfolioPictures.splice(index, 1);
      }

      return {
        ...this.state,
        portfolio: {
          ...portfolio,
          pictures: newPortfolioPictures
        }
      };
    });
  }

  savePortfolioOrder = async () => {
    // TODO use patch call on API to persist this
    fetch(`${rootUrl}portfolios/${this.state.portfolio.id}/pictures`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getAccessToken()
      },
      body: JSON.stringify(this.state.portfolio.pictures)
    })
    .then(response => this.handleResponse(response))
    .then(data => {
      const updatedPortfolio = {
        ...this.state.portfolio,
        pictures: data
      }

      this.setState({...this.state, savedPortfolio: updatedPortfolio, portfolios: updatedPortfolio });
    })
    .catch(this.handleUncaughtError);
  }

  // specify upload params and url for your files
  getUploadParams = ({ file }) => { 
    const formData = new FormData();
    formData.append('pictures', file);

    return { 
      url: `${rootUrl}portfolios/${this.state.portfolio.id}/pictures`,
      body: formData,
      headers: {
        'Authorization': 'Bearer ' + this.getAccessToken()
      }
    }
  }

  // called every time a file's `status` changes
  handleUploadChangeStatus = (fileWithMeta, status) => {
    if(status === "restarted") {
      return {
        headers: {
          'Authorization': 'Bearer ' + this.getAccessToken()
        }
      }
    }

    if(status === "done") {
        const res = JSON.parse(fileWithMeta.xhr.response);

        this.setState(({portfolio}) => ({
          ...this.state,
          portfolio: {
            ...portfolio,
            pictures: [...res, ...portfolio.pictures]
          }
        }));

        fileWithMeta.remove();
    } else if(status === "error_upload" && fileWithMeta.xhr?.status === 401) {
      pausedFileUploads.push(fileWithMeta);
      this.logout();
    }
  }

  uploadPictures = async (files) => {
    const formData = new FormData();
    for (const file of files) {
        formData.append('pictures', file);
    }

    try {
        const res = await this.handleResponse(await fetch(`${rootUrl}portfolios/${this.state.portfolio.id}/pictures`,
        {
            body: formData,
            method: "post",
            headers: {
              'Authorization': 'Bearer ' + this.getAccessToken()
            }
        }));

        if(res) {
          this.setState(({portfolio}) => ({
            ...this.state,
            portfolio: {
              ...portfolio,
              pictures: [...res, ...portfolio.pictures]
            }
          }));
        }
    } catch (ex) {
        this.handleUncaughtError(ex);
    }
  }

  componentDidMount(prevProps) {
    this.checkLogin().then(() => {
      this.loadPortfolios();
    });
  }

  login = async (event) => {
    fetch(rootUrl + 'token', 
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getAccessToken()
      },
      body: JSON.stringify(event)
    })
    .then(response => response.json())
    .then((response) => {
      if(response.accessToken) {
        this.setAccessToken(response.accessToken);
        this.loadPortfolios();
      } else {
        alert("Invalid username or password.");
      }
    })
  }

  render() {
    const { children } = this.props

    return (
      <AppStateContext.Provider
        value={{
          ...this.state
        }}
      >
        {children}
      </AppStateContext.Provider>
    )
  }
}

export default AppStateContext

export { AppStateProvider }